import React from "react"
import Layout from "./layout"
import Home from "./Home"
import SEO from "./seo"
import { graphql } from "gatsby"
import Amplify from "aws-amplify"
import awsconfig from "../utils/updateAmplifyConfig"

Amplify.configure(awsconfig)

const IndexPage = ({ data, pageContext }) => {
  const { nodes } = data.allProject
  console.log("CityPage", { nodes })
  return (
    <Layout>
      <SEO title={`Vos commerçants à ${pageContext.city}`} />
      <Home projects={nodes} pageType="city" city={pageContext.city} />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query($citySlug: String!) {
    allProject(filter: { citySlug: { eq: $citySlug } }) {
      nodes {
        id
        slug
        name
        meta {
          city
          zip_code
          url
          description
          fullDescription
          background_url
          logo_url
        }
        background_url {
          childImageSharp {
            gatsbyImageData
          }
        }
        logo_url {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
    }
  }
`
